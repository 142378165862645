@tailwind base;

.wordwrap
{
/* wrap long text and urls */
white-space: pre; /* CSS 2.0 */
white-space: pre-wrap; /* CSS 2.1 */
white-space: pre-line; /* CSS 3.0 */
white-space: -pre-wrap; /* Opera 4-6 */
white-space: -o-pre-wrap; /* Opera 7 */
white-space: -moz-pre-wrap; /* Mozilla */
word-wrap: break-word; /* IE 5+ */
} 

html {
  scroll-behavior: smooth;
}
@tailwind components;
@tailwind utilities;

h1 {
  -webkit-text-stroke-color: black;
  -webkit-text-stroke-width: 0.6px;
}

.flip-card {
  background-color: transparent;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #FB7D8A;
  color: white;
}

.flip-card-back {
  background-color: #FB7D8A;
  color: white;
  transform: rotateY(180deg);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-ExtraBold.otf");
  font-family: "Play", sans-serif;
  font-family: "Bungee", cursive;
}

empty {
  margin: 12px; 
}
